import service from '@/utils/request';

// @Tags DdpDealerGrade
// @Summary 创建DdpDealerGrade
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerGrade true "创建DdpDealerGrade"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /ddpDealerGrade/createDdpDealerGrade [post]
export var createDdpDealerGrade = function createDdpDealerGrade(data) {
  return service({
    url: "/ddpDealerGrade/createDdpDealerGrade",
    method: 'post',
    data: data
  });
};

// @Tags DdpDealerGrade
// @Summary 删除DdpDealerGrade
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerGrade true "删除DdpDealerGrade"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /ddpDealerGrade/deleteDdpDealerGrade [delete]
export var deleteDdpDealerGrade = function deleteDdpDealerGrade(data) {
  return service({
    url: "/ddpDealerGrade/deleteDdpDealerGrade",
    method: 'delete',
    data: data
  });
};

// @Tags DdpDealerGrade
// @Summary 删除DdpDealerGrade
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除DdpDealerGrade"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /ddpDealerGrade/deleteDdpDealerGrade [delete]
export var deleteDdpDealerGradeByIds = function deleteDdpDealerGradeByIds(data) {
  return service({
    url: "/ddpDealerGrade/deleteDdpDealerGradeByIds",
    method: 'delete',
    data: data
  });
};

// @Tags DdpDealerGrade
// @Summary 更新DdpDealerGrade
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerGrade true "更新DdpDealerGrade"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /ddpDealerGrade/updateDdpDealerGrade [put]
export var updateDdpDealerGrade = function updateDdpDealerGrade(data) {
  return service({
    url: "/ddpDealerGrade/updateDdpDealerGrade",
    method: 'put',
    data: data
  });
};

// @Tags DdpDealerGrade
// @Summary 用id查询DdpDealerGrade
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DdpDealerGrade true "用id查询DdpDealerGrade"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /ddpDealerGrade/findDdpDealerGrade [get]
export var findDdpDealerGrade = function findDdpDealerGrade(params) {
  return service({
    url: "/ddpDealerGrade/findDdpDealerGrade",
    method: 'get',
    params: params
  });
};

// @Tags DdpDealerGrade
// @Summary 分页获取DdpDealerGrade列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取DdpDealerGrade列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /ddpDealerGrade/getDdpDealerGradeList [get]
export var getDdpDealerGradeList = function getDdpDealerGradeList(params) {
  return service({
    url: "/ddpDealerGrade/getDdpDealerGradeList",
    method: 'get',
    params: params
  });
};